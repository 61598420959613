a {
    text-decoration: none;
    color: white;
}

.faq-box a, #get-here a {
    text-decoration: underline;
}

#get-here a{
    color: #0B043E;
}

.video-outer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    overflow: hidden;
}

.header-flower, .rock-element{
    position: absolute;
    z-index: 99;
    left: -8rem;
    bottom: -15rem;
    width: min(35rem, 50%);
}

.rock-element {
    left:auto;
    right:0rem;
    bottom: -22rem;
}

.claim-video {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 100%;
    border-radius: inherit;
}

.video-padding {
    padding-top: calc(860 / 1788 * 100%);
    position: relative;
    object-fit:contain
}

.pin-image {
    width: 16.875vw;
    min-width: 16.875vw;
    position: relative;
    margin-left: 4rem;
    z-index: 3;
}

.pin-image:after{
    position: absolute;
    content:"";
    left:0rem;
    border-radius: 50rem;
    width: 100%;
    height:95%;
    border: 10px solid rgba(255,255,255, 0.8);
}

.experiences-section{
    background: white;
    /*background-image: url("assets/images/experiences/bkg.jpg");*/
}

.experience-card{
    width:100%;
    position:relative;
    cursor:pointer;
    border-radius: 0.6rem;
}

.hover-box{
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
    overflow: hidden;
    border-radius: inherit;
}

.experience-card:hover{
    z-index: 99;
    scale: 102%;
    box-shadow: 0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12);
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
}

.experience-card:hover .hover-box {
    display: block;
}

.experience-card:hover .experience-button{
    opacity: 1;
}

.rotating {
    top: 50%;
    left: 50%;
    -webkit-animation:spin 6s linear infinite;
    -moz-animation:spin 6s linear infinite;
    animation:spin 6s linear infinite;
}

.card-1, .card-2 {
    background: #005E2E;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.tickets-section{
    background: #FFF5E8;
}

.experiences-section, .tickets-section {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.spotify-container{
    position: absolute;
    bottom:5%;
    right:5%;
    width: min(7rem, 20vw);
    height: min(7rem, 20vw);
}

@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}

@media screen and (max-width: 767px) {
    .video-padding{
        padding-top: calc(1488 / 860 * 100%);
    }

    .video-outer{
        border-radius: 0 0 20px 20px ;
    }

    .header-flower{
        position: absolute;
        z-index: 99;
        left: -8rem;
        bottom: 0rem;
        width: 25rem;
    }

    .rock-element{
        position: absolute;
        z-index: 99;
        bottom:-12rem;
        width: 18rem;
    }

    .hover-box {
        display: none !important;
    }

    .experience-card{
        box-shadow: none !important;
        background-image: none !important;
    }

    .experience-button{
        display: none !important;
    }

}

.gradient{
    background: linear-gradient(0deg, #005E2E 0%, #133B1E 100%);
}

.colored {
    filter: invert(54%) sepia(28%) saturate(6923%) hue-rotate(15deg) brightness(113%) contrast(99%);
}

.neutral {
    filter: brightness(0%) saturate(100%);
}