@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;600;700&display=swap');
@import url('https://use.typekit.net/zhe2hur.css');

/*
Bebas Neue Pro Regular
  font-family: "bebas-neue-pro", sans-serif;
  font-weight: 400;
  font-style: normal;
Bebas Neue Pro Italic
  font-family: "bebas-neue-pro", sans-serif;
  font-weight: 400;
  font-style: italic;
Bebas Neue Pro Bold
  font-family: "bebas-neue-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
*/

@font-face {
  font-family: Termina;
  src: url(./assets/fonts/Termina/Termina-Light.otf);
  font-weight: 300;
}

@font-face {
  font-family: Termina;
  src: url(./assets/fonts/Termina/Termina-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: Termina;
  src: url(./assets/fonts/Termina/Termina-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: Termina;
  src: url(./assets/fonts/Termina/Termina-Demi.otf);
  font-weight: 600;
}

@font-face {
  font-family: Termina;
  src: url(./assets/fonts/Termina/Termina-Bold.otf);
  font-weight: 700;
}

@font-face {
  font-family: Blur;
  src: url(./assets/fonts/Blur/Blur-Medium.otf) format('opentype');
  font-weight: 500;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
